exports.getOfferTimeFromTS = function(offer, format = "long")
{
	return exports.getOfferTime(offer.startDateTime_timestamp * 1000, offer.endDateTime_timestamp * 1000, format);
}

//start and end time in multiple formats
exports.getOfferTime = function(start, end, format = "short")
{
	var null_date = new Date(0);
	
	let dateFormat = {};
	if (format === "short"){ dateFormat = { month: 'short', day: 'numeric' }; }
	else if (format === "long"){ dateFormat = { month: 'short', day: 'numeric', year:'numeric' };}
			  
	let startTime = new Date(start);
	let startTimeJS = startTime;
	startTime = startTime.toLocaleDateString("en-US", dateFormat);

	let endTime = new Date(end);
	let endTimeJS = endTime;
	endTime = endTime.toLocaleDateString("en-US", dateFormat);

	return {'startTime' : startTime, 'endTime' : endTime, 'startTimeJS' : startTimeJS, 'endTimeJS' : endTimeJS, 'startSet' : (startTimeJS > null_date)}
}

exports.isExpiredOffer = function(offer)
{
	const now = new Date();
	const end = new Date(offer.endDateTime);
	return  end < now ;
}

//is it deal of the day 
exports.isDealOfTheDay = function(offer)
{
	let deal = false;
	let currentDay = new Date();
	currentDay = currentDay.getDay();
	//convert to ISO
	currentDay = (currentDay + 6) % 7 + 1;
	
	if(offer.dealOfTheDay && (offer.days.length > 0) && (offer.days.length < 7)){
		let days = [];
		for (var i in offer.days) { days.push(offer.days[i].iso);}	
		
		//should contain current day and should not contain next day
		deal = days.includes(currentDay);
			
		let nextDay = (currentDay + 7 ) %7 + 1;
		deal &= !days.includes(nextDay);
	}
	
	return deal;
}

//"credit,debit" kind of card types string 
exports.getCardTypeString = function(offer, joinString=", " , addPostamble = false)
{
	let ccType = [];
	for (var i in offer.cards) { ccType.push(offer.cards[i].transactionType);}
	let uniqueCC = [...new Set(ccType)]; 
	
	let ccString = uniqueCC.join(joinString);
	
	if (addPostamble && ccString.includes('Net')) {
		ccString += " customers";
	} else if (addPostamble && ccString.includes('Credit')){
		ccString += " card holders";
	} else if (addPostamble && ccString.includes('Debit')){
		ccString += " card holders";
	}
	
	return ccString;
}

//channles string 
exports.getChannelsString = function(offer, joinString=", ")
{
	let channels = [];
	for (var i in offer.channels) { channels.push(offer.channels[i].name);}
	
	return channels.join(joinString);
}

//all full cards decsription name 
exports.getApplicableOnString = function(offer, joinString=", ")
{
	//get credit, debit string from all cards data
	let applicableOn = [];
	for (var i in offer.cards) { applicableOn.push(offer.cards[i].description);}
	let uniqueCC = [...new Set(applicableOn)]; 
	
	return uniqueCC.join(joinString);
}

exports.getTimeString = function(offer, preamble="", postamble=" | ")
{
	let timeString = "", startString="";
	
	//startTime, endTime 14:00, makes sense when both are set
	if (offer.startTime && offer.endTime){		
		let [sh, sm] = offer.startTime.split(":");
		timeString += (sh%12+12*(sh%12==0))+":" + sm + (sh >= 12 ? 'pm' : 'am');
		
		timeString += " - ";

		let [eh, em] = offer.endTime.split(":");
		timeString += (eh%12+12*(eh%12==0))+":" + em + (eh >= 12 ? 'pm' : 'am');
		
		timeString += postamble;
	}
	
	return timeString;
}

//if applicable on specific days, days string
exports.getDaysString = function(offer, preamble="Only on ", postamble="", joinString=", ")
{
	let daysString = "";
	
	if(offer.days.length === 7){daysString = "whole week";}
	
	if((offer.days.length > 0) && (offer.days.length < 7)){
		let days = [];
		for (var i in offer.days) { days.push(offer.days[i].name);}	
		daysString = preamble + days.join(joinString) + postamble ;
	}
	
	return daysString;
}

//offer string like Rs. 100 instant discount
exports.getOfferString = function(offer, preamble="Get ", naString="Avail this offer" , bold=false)
{
	let offerString="";
	const bTagS = bold ?  "<b>" : "";
	const bTagE = bold ?  "</b>" : "";
	
	if(offer.discountPercentage){
		offerString = preamble + bTagS + offer.discountPercentage + "% " + bTagE +  offer.type[0].name;
	} else if (offer.absoluteDiscount){
		offerString = preamble + bTagS + "Rs. " + offer.absoluteDiscount + " " + bTagE + offer.type[0].name;
	} else {
		//offers like EMI, rewards etc
		offerString =  bTagS + naString + bTagE;
	}
	
	return offerString;
}

//featured offer string with bold percentage
exports.getFeaturedOfferString = function(offer, preamble="Get ", naString="Get Exciting offers" )
{
	let offerString = exports.getOfferString(offer, preamble, naString, true);
	
	if(offer.bank){
		offerString += " on " + offer.store.name + " with ";
		if(offer.cards.length > 1){
			offerString += offer.bank.name + " cards";
		} else {
			offerString += offer.cards[0].description;
		}
	}

	return offerString.toUpperCase();
}

exports.getFeaturedOfferTimerString = function(offer)
{
	let startString = "";
	
	const offerTime = exports.getOfferTime(offer.startDateTime_timestamp * 1000, offer.endDateTime_timestamp * 1000);
	const startDate = offerTime.startTimeJS;
	const endDate = offerTime.endTimeJS;
	const current = new Date();
			
	if (current < startDate){ startString = "Starts on " + offerTime.startTime ; }
	else if ( (current >= startDate) && (current <= endDate)) { startString = "Ends on " + offerTime.endTime ; }
	else { startString = "Ended on " + offerTime.endTime ; }
	
	return startString;
}

exports.getValidityString = function(offer)
{
	let validity = exports.getDaysString(offer, "on every ");
	let offerTime = exports.getOfferTime(offer.startDateTime, offer.endDateTime);

	if (offerTime.startSet) { validity += " from " + offerTime.startTime + " to " + offerTime.endTime; }
	else { validity += " till " + offerTime.endTime; }
	
	return validity;
}

exports.getAboutCardString = function(offer, bookNow = true)
{
	const bookNowStrings = ["Hurry up and make use of this offer now!", "Avail this offer now!", "Book now!"]
	let store = "";
	if (offer.store){
		store = " on the " + offer.store.name + " store";
	}
	
	let aboutCard = exports.getOfferString(offer) + " on every purchase" + store + " for " + exports.getApplicableOnString(offer) + " holders. The offer  is valid " + exports.getValidityString(offer) + "." ;
	if (bookNow ) { aboutCard += " " + bookNowStrings[Math.floor(Math.random()*bookNowStrings.length)]; }

	return aboutCard;
}

exports.getOfferSaleEventSchema = function(search_offer, page_url)
{
	// console.log(search_offer);
	
	const hasBank = search_offer.offer.bank ? true : false;
	const hasStore = search_offer.offer.store ? true : false;
	
	const name = hasStore ? search_offer.offer.store.name : search_offer.offer.bank.name;
	const logo = hasStore ? search_offer.offer.store.logoUrl : search_offer.offer.bank.logoUrl;
	// const cat = search_offer.offer.categories[0].name;
	const url = "https://happycredit.in" + page_url;
	const desc = exports.getOfferString(search_offer.offer, "", search_offer.offer.type[0].name + " offer");
	const offer_name =  (hasBank && hasStore ) ? desc + ": " + search_offer.offer.bank.name : desc ;
	
	let ts = exports.getOfferTimeFromTS(search_offer.offer);
	
	let x = new Date();
	let offset_timezone = x.getTimezoneOffset()*60*1000; //offset is in -ve, so negate again
	const sd = ts.startSet ? new Date(search_offer.offer.startDateTime_timestamp * 1000 - offset_timezone).toISOString().split('T')[0] : new Date(x.getTime() - offset_timezone).toISOString().split('T')[0] ;
	const ed = new Date(search_offer.offer.endDateTime_timestamp * 1000 - offset_timezone).toISOString().split('T')[0];

	// console.log(x);
	// console.log(x.toISOString());
	// console.log(offset_timezone);
	// console.log(search_offer.offer.startDateTime_timestamp);
	// console.log(search_offer.offer.endDateTime_timestamp);
	// console.log(sd);
	// console.log(ed);
	// console.log(offer_name);
	// console.log(search_offer);
 
	let schema = {
		"@context"      : "https://schema.org",
		"@type"         : "SaleEvent",
		"name"          : offer_name,
		"url"           : url + "?src=SaleEventSchema",
		"startDate"     : sd,
		"endDate"       : ed,
		"image"         : logo,
		"location": {
			"@type"       : "Place",
			"name"        : name,
			"url"         : url,
			"address"     : name,
			"description" : desc
		}
	}
	
	// console.log(schema);
	return JSON.stringify(schema);
	
}