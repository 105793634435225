import React from "react"
import DH from "../../utils/details-helpers";
import HCFunctions from "../../utils/hc-functions";
import { Link } from "gatsby"

const BookNow = ( {offer} ) => {
	let hasBank = offer.bank ? true : false;
	let hasStore = offer.store ? true : false;
	const landingUrl = hasStore ? offer.store.website : offer.link.landingUrl;
	
	const expired = DH.isExpiredOffer(offer); 
	const dealDay = DH.isDealOfTheDay(offer); 
	let daysString = DH.getDaysString(offer, "Available on ", ". ");
	
	let bookNowClass = "book-now";
	let helperString = offer.code ? "On clicking Book Now, coupon will be auto copied to your clipboard." : "";
	let infoString = "";
	
	if (expired){ 
		bookNowClass = "book-now disble";
	} else if (!!offer.dealOfTheDay && !dealDay) {
		infoString = daysString; 
	} else if (!!offer.dealOfTheDay && dealDay) { 
		infoString = "Deal of the day."; 
	}
	
	return(
	<div>
		<a href={landingUrl} className={bookNowClass} target="_blank" rel="nofollow noopener noreferrer"  
			onClick={(e) => {
				if (offer.code) {
					HCFunctions.copyTextToClipboard(offer.code);
				}
			}}
		  >BOOK NOW</a>
		  {expired && hasBank && <p className="coupon-auto-apply">Offer expired. You can check other active <Link to={offer.bank.page.url}>{offer.bank.name + " offers"}</Link>.</p>}
		  {expired && !hasBank && <p className="coupon-auto-apply">Offer expired.</p>}
		  {!expired && <p className="coupon-auto-apply">{infoString} {helperString}</p>}
	</div>
	)
}

export default BookNow