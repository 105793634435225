import React from "react"
import DH from "../../utils/details-helpers";
import { Link } from "gatsby"

const OffersTable = ({pageType, offers}) => {
	
	// console.log(offers);
	// console.log(pageType);
	
	if(!Array.isArray(offers) || offers.length == 0) {
		return '';
	}

	const MAX_TOP_OFFERS = 5;
	offers = offers.slice(0, MAX_TOP_OFFERS);
	
	return(
	<div className="details-offer">
		<table>
			<thead>
				<tr>
				  { (pageType != "Bank") && <th>Bank Name</th> }
				  { (pageType != "Store") && <th>Store Name</th> }
				  <th>Discount</th>
				  <th>Valid Till</th>
				</tr>
			</thead>	
			<tbody>
				{offers.map(o => (
				<tr key={o.id}>
				  { (pageType != "Bank") && <td>{o.offer.bank ? o.offer.bank.name : "All banks"}</td> }
				  { (pageType != "Store") && <td>{o.offer.store ? o.offer.store.name : "All stores"}</td> }
				  <td><Link to={o.gatsbyUrl}>{DH.getOfferString(o.offer, "", o.offer.type[0].name + " offer")}</Link></td>
				  <td>{DH.getOfferTimeFromTS(o.offer).endTime}</td>
				</tr>
				))}
			</tbody>	
		</table>
	</div>
)}

export default OffersTable