import React from "react"
import "../../styles/scss/pages/details/offers-list.scss"
import DH from "../../utils/details-helpers";

const OffersList = ({offer}) => {
	
	// console.log(offer);
	
	let applicableOn = DH.getApplicableOnString(offer); 
	let offerTime = DH.getOfferTime(offer.startDateTime, offer.endDateTime, "long");
	let daysString = DH.getDaysString(offer, "Only on ", " | ");
	let timeString = DH.getTimeString(offer);
	let offerString = DH.getOfferString(offer, "", offer.type[0].name + " offer");
	let channels = DH.getChannelsString(offer);
	const validity = offerTime.startSet ? (offerTime.startTime + " to " + offerTime.endTime) : ("Till " + offerTime.endTime);
	
	return(
	<div className="list-offers">
	<div className="container">
	  <div className="box">
		<span>Offer:</span>
		<p>{offerString}</p>
	  </div>
	  <div className="box">
		<span>Applicable on:</span>
		<p>{applicableOn}</p>
	  </div>
	  <div className="box">
		<span>Valid from:</span>
		<p>{timeString} {daysString} {validity}</p>
	  </div>
	  <div className="box">
		<span>Booking Channel:</span>
		<p>{offer.store && offer.store.name + " "}{channels}</p>
	  </div>
	</div>
	</div>
)}

export default OffersList