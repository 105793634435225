import React from "react"
import "../../styles/scss/pages/details/banner.scss"
import Breadcrumbs from "../common/breadcrumbs";
import DH from "../../utils/details-helpers";
import BookNow from "./book-now";

const Banner = ( {offer} ) => {
	
	let hasBank = offer.bank ? true : false;
	const hasCardBrand = !!offer.cards[0].brand ? true : false;
	let hasStore = offer.store ? true : false;
	let offerTime = DH.getOfferTime(offer.startDateTime, offer.endDateTime, "long");
	let ccType = DH.getCardTypeString(offer, " and ", true); 
	let code = (offer.code) ? offer.code : "Not required!"
	let desktopBannerUrl = offer.categories[0].detailsPageBannerUrl;
	const validity = offerTime.startSet ? (offerTime.startTime + " - " + offerTime.endTime) : ("Till " + offerTime.endTime);
	
	return(
	<div className="banner">
	<div className="container">
	  <Breadcrumbs  pageType={offer.page.type} pageData={offer} pageUrl={offer.page.url} />
	  <div className="banner-coupon">
		<img className="details-banner-img" src={desktopBannerUrl} alt={offer.page.h1 + " Banner"} />
		{hasBank && <img className="bnk-logo" src={offer.bank.logoUrl} alt={offer.bank.name + " Offer"} />}
		{!hasBank && hasCardBrand && <img className="bnk-logo" src={offer.cards[0].brand.logoUrl} alt="All banks offer" />}
		{hasStore && <img className="bnk-logo" src={offer.store.logoUrl} alt={offer.store.name + " Offer"} />}
		<h1>{offer.page.h1.replace(/&amp;/gi, "&")}</h1>
		{hasBank && hasStore && <h2>Exclusive for {offer.bank.name} {ccType} on {offer.store.name}.</h2>}
		{hasBank && !hasStore && <h2>Exclusive for {offer.bank.name} {ccType}.</h2>}
		<p className="offer-valid">Offer Validity: {validity}</p>
		<div className="select-coupon">
		  <span id="coupon-code">Coupon Code <hr />{code}</span>
		  <BookNow offer={offer} />
		</div>
	  </div>
	</div>
	</div>
	)
}

export default Banner