import React from "react"
import useForm from "react-hook-form";
import Lead from "../../utils/lead-helper";

const Subscribe = () => {
    const { handleSubmit, register, errors } = useForm();
    const onSubmit = values => {
        if (Object.keys(errors).length > 0) {
            return;
        }
        let leadHelper = new Lead();
        document.querySelector(".successs").classList.add("show");
        leadHelper.createLead(values);
    };
    return (
        <div className="subscribe-wrapper">
            <div className="subscribe-now">
                <div className="container">
                    <h2>Subscribe Now!</h2>
                    <p>Never miss out on active offers on your credit and debit cards !!</p>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <input
                            name="email"
                            type="email"
                            ref={register({
                                required: 'Required',
                                pattern: {
                                    value: /^[^@]+@[^@]+$/i,
                                    message: "Invalid email address"
                                }
                            })}
                            placeholder="Enter Email Adress Here"
                            aria-label="name"
                        />
                        <button type="submit">Submit <span></span></button>
                        <p className="error">{errors.email && errors.email.message}</p>
                        <p className="successs">Thank You!</p>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Subscribe