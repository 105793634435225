import React, { Component } from 'react';
import { renderToString } from 'react-dom/server'
import DH from "../../utils/details-helpers";
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby";
import OffersTable from "./offers-table";

//do not delete these functions, its indirectly being used in templates, ignore gatsby warning around these
function maxUsageFaqTag(strings, maxUsageCount)
{
	if (maxUsageCount){
		return "This offer could be used maximum " + maxUsageCount + " time/s.";
	} else {
		return "There is no such offer usage limitation in this offer.";
	}
}

function maxDiscountFaqTag(strings, maxDiscount)
{
	if (maxDiscount){
		return "Discount upto maximum Rs. " + maxDiscount + " could be availed in this offer.";
	} else {
		return "There is no such limitation on discount amount in this offer.";
	}
}

function validDaysFaqTag(strings, validDays)
{
	if (validDays === ""){
		return "This offer is applicable on all days during validity period.";
	} else {
		return "This offer is applicable " + validDays + " during validity period";
	}
}

function customerTypeFaqTag(strings, newStoreCustomersOnly, newBankCustomersOnly, bank, store)
{
	if (newStoreCustomersOnly) {
		return "This offer is only applicable for new  " + store + "  customers.";
	}
	else if (newBankCustomersOnly) {
		return "This offer is only applicable for new " + bank + " bank customers.";
	}
	
	return "This offer is applicable for all new and existing bank and store/merchant customers.";
}

function topOffersTableFaqTag(strings, pageType, searchData)
{
	if (searchData.length > 0){
		return renderToString(<OffersTable pageType={pageType} offers={searchData} />);
	} else {
		return "Currently no offers are available. You can subscribe to know when new offers become available.";
	}
}

function topOffersTableSchemaFaqTag(strings, pageType, searchData)
{
	if (searchData.length > 0){
		const MAX_TOP_OFFERS = 5;
		searchData = searchData.slice(0, MAX_TOP_OFFERS);
		
		let schema = "";
		for (var i in searchData){
			let o = searchData[i];
			const index = +i+1;
			schema += index + ") ";
			if(pageType != "Bank"){ schema += o.offer.bank ? o.offer.bank.name : "All banks" ; schema += ", ";}
			if(pageType != "Store"){schema += o.offer.store ? o.offer.store.name : "All stores" ; schema += ", ";}
			schema += "<a href='" + o.gatsbyUrl + "'>" + DH.getOfferString(o.offer, "", o.offer.type[0].name + " offer") + '</a>';
			schema += ", till ";
			schema += DH.getOfferTimeFromTS(o.offer).endTime;
			schema += ". ";
		}
		return schema;
	} else {
		return "Currently no offers are available. You can subscribe to know when new offers become available.";
	}
}


function replaceFaqTag(strings, ...values)
{
   let str = '';
   strings.forEach((string, i) => {
       str += string + (values[i] || '');
   });
   return str;
}


function prepareFaqInputs(page, categories){
	let faqInputs = {};
	faqInputs.type = page.type;
	faqInputs.h1 = page.h1;
	
	let month = new Date();
	faqInputs.month = month.toLocaleDateString("en-US", { month: 'long', year:'numeric' });	
	
	//populate search data to generate table
	const offers = page.searchData ? page.searchData.offers.data : [];
	faqInputs.searchData = offers;
	
	let clinks = [];
	for (var c in categories){
		clinks.push("<a href='" + categories[c].page.url + "'>" + categories[c].name + "</a>");
	}
	faqInputs.categoriesLinks = clinks.join(", ");
	// console.log(faqInputs.categoriesLinks);
	
	if (page.type === "Category" || page.type === "SubCategory" || page.type === "Bank" || page.type === "Store" ) { 
		faqInputs.name = page.listData.name;
	} else if (page.type === "List" ) { 
		faqInputs.listData = Object.assign({}, page.listData);
	} else if (page.type === "Offer" ) { 
		//overwrite name
		faqInputs.name = "Offer";
		
		//copy these objects, assigning would create reference and change both the objects
		// faqInputs.bank = Object.assign({}, page.offer.bank);
		// faqInputs.store = Object.assign({}, page.offer.store);
		faqInputs.offer = Object.assign({}, page.offer);
		// console.log(faqInputs);
		
		faqInputs.offer.applicableOn = DH.getApplicableOnString(page.offer);
		faqInputs.offer.channels = DH.getChannelsString(page.offer);
		faqInputs.offer.aboutCard = DH.getAboutCardString(page.offer, false);
		faqInputs.offer.cardType = DH.getCardTypeString(page.offer, ", ", true);
		faqInputs.offer.validity = DH.getValidityString(page.offer);
		faqInputs.offer.validDays = DH.getDaysString(page.offer, "only on ");
	}

	return faqInputs;
}

function prepareQuestions(faqInputs){
	var faqs = require('../common/faqs.json'); 
	// console.log("faqInputs");
	// console.log(faqInputs);
	
	var pq = [];
	
	for (var i in faqs) {
		const f = faqs[i];
		const fTypes = f.pageType;
	  
		// console.log(f);
		
		//skip through unwanted questions
		if(! fTypes.includes(faqInputs.type)){ continue;}
		if (f.q.concat(f.a).includes('${data.offer.bank') && !faqInputs.offer.bank ){ continue;}
		if (f.q.concat(f.a).includes('${data.offer.store') && !faqInputs.offer.store ){ continue;}
		if (f.q.concat(f.a).includes('${data.offer') && !faqInputs.offer ){ continue;}
		
		//some changes to use same questions on list pages, this might change per question
		if(faqInputs.type === "List"){
			//first preference to store questions
			if(fTypes.includes("Store") && faqInputs.listData.store){
				faqInputs.name = faqInputs.listData.store.name;
			} else if(fTypes.includes("Bank") && faqInputs.listData.bank){
				//bank is mandatory in all list pages, so if question is there and store not there use bank name
				faqInputs.name = faqInputs.listData.bank.name;
			} else {
				//skip this question
				continue;
			}
		}
		
		//do not delete next line, its indirectly being used in literal templates
		var data = faqInputs;

		//TODO: find a better way than using eval or sanitize //Jitendra
		//if string has "FaqTag`" and then ends with `, use eval
		const q = f.q.includes("FaqTag`") ? eval(f.q) : f.q;
		const a = f.a.includes("FaqTag`") ? eval(f.a) : f.a;
		
		//three lines commented below to remove schema tags
		//let s = a;
		//if (f.s) { s =  f.s.includes("FaqTag`") ? eval(f.s) : f.s };
		//pq.push({'index': i, 'pageType' : f.pageType[0], 'q' : q, 'a' : a, 's' : s, 'priority' : f.priority});
		
		pq.push({'index': i, 'pageType' : f.pageType[0], 'q' : q, 'a' : a, 'priority' : f.priority});

	}
	// console.log(pq);
	
	return pq;
}

function generateSchema(pq)
{
	//this function is not needed..
	return "";
	
	let qList = [];
	
	for (var i in pq) {
		qList.push( { "@type": "Question", "name": pq[i].q, "acceptedAnswer": { "@type": "Answer",  "text": pq[i].s } });
	}
	
	let schema = {
		"@context": "https://schema.org",
		"@type": "FAQPage",
		"mainEntity": qList
	}	
	
	// console.log(schema);
	return JSON.stringify(schema);
}


class Faqs extends Component {
  constructor(props) {
    super(props)
    this.toggleFaq=this.toggleFaq.bind(this);
  }
  
  componentDidMount() {
	//open first question
    let els = document.getElementsByClassName('qust');
    if (els && els[0]){ els[0].classList.add("active"); }
  }
  
  toggleFaq(e){
    let els = document.getElementsByClassName('qust active');
    if(els && els[0]){
		els[0].classList.remove('active');
    }
    e.target.classList.add("active");
  }
 
 
  render() {

	let { page, data } = this.props;
	// console.log(data);
	
	let faqInputs = prepareFaqInputs(page, data.hc.categories);
	let pq = prepareQuestions(faqInputs);
	if ( pq.length === 0 ){ return "";}
	
	let faq_h2 ="";
	if(page.type === "Offer"){
		if (page.offer.bank) { faq_h2 += page.offer.bank.name + " "; }
		if (page.offer.store) { faq_h2 += page.offer.store.name + " "; }
		faq_h2 += "offer";
	} else {
		faq_h2 = page.h1;
	}
	
	return (
	<>	
		{/*<Helmet>
			<script type="application/ld+json">{generateSchema(pq)}</script>
		</Helmet> */}
		<div className="faq-wrapper">
			<div className="container faq">
			  <h2>{faq_h2} FAQs</h2>
				<ul className="accordion">

					{pq.map(q => (
					<li key={"faq-" + q.index}>
					  <h3 className="qust" onClick={(e) =>this.toggleFaq(e)}>{q.q}</h3>
					  <div className="accordion-info" dangerouslySetInnerHTML={{ __html: q.a }} />
					</li>
					))}
				
				</ul>
			</div>
		</div>
	</>	
)
}
};

export const FaqsSection = props => (
  <StaticQuery
    query={graphql`
	  query {
		hc {
		  categories {
			id
			name
			page {url gatsbyUrl}
			subCategories{id name page {url gatsbyUrl}}
		  }
		}
	  }
    `}
    render={data => <Faqs {...props} data={data} />}
  />
)

export default FaqsSection