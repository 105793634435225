import React from "react"
import "../../styles/scss/pages/details/offers-details.scss"
import HCFunctions from "../../utils/hc-functions";
import DH from "../../utils/details-helpers";
import BookNow from "./book-now";

const OfferDetails = ({offer}) => {
	
	// console.log(offer);
	
	const landingUrl = offer.store ? offer.store.website : offer.link.landingUrl;

	//get rid of unwanted strings from special notes
	let specialNotes = "";
	if (offer.specialNotes.html){
		//remove br and &amp; from string.
		specialNotes = offer.specialNotes.html;
		specialNotes = specialNotes.replace(/<br[^>]*>/gi, " ");
		specialNotes = specialNotes.replace(/&#x3C;br>/gi, " ");
		specialNotes = specialNotes.replace(/&amp;/gi, "&");
		specialNotes = specialNotes.replace(/[ ]+/gi, " ");
		
		if(specialNotes === "<p></p>"){ specialNotes = ""; }
	}
	
	// console.log(offer.offerExtensions);
	// console.log(specialNotes);
	
	//prepare extension table
	let rfirst, rmid, rlast;
	let extRows = 0;
	if (Array.isArray(offer.offerExtensions) && offer.offerExtensions.length > 0){
		extRows = offer.offerExtensions.length;

		rfirst = offer.offerExtensions.slice(0,1);
		rmid = offer.offerExtensions.slice(1,extRows-1);
		rlast = offer.offerExtensions.slice(extRows-1, extRows);
	}
	
	//services
	const serviceTime = DH.getOfferTime(offer.serviceStartDate, offer.serviceEndDate, "long");
	const service = serviceTime.startSet ? ("Between " + serviceTime.startTime + " and " + serviceTime.endTime) : ("Till " + serviceTime.endTime);
	
	let sectionVisible = (extRows > 0) || (offer.subCategoryL2s.length > 0) || (offer.maxDiscount) || (offer.maxUsageCount) || (offer.otherCriteria) || (offer.newBankCustomersOnly) || (offer.newStoreCustomersOnly) || (specialNotes !== "") || (offer.serviceEndDate);
	
	return(
	<>
	{ sectionVisible && <div className="details-offer">
		<div className="container">
		<img className="dots" src={'/images/details-dot.svg'} alt="Banner dots patterm offer page" />
		<img className="desk-graph" src={'/images/detail-offer.svg'} alt="Banner offer page" />
		  <h2>Offer Details:</h2>
		  { 
			extRows > 0  &&
			  <table>
				<tr>
				  <th>Coupon Code</th>
				  <th>Offer Details</th>
				  <th>Minimum Booking Amount</th>
				</tr>
				<tr>
				  <td rowspan={extRows}>{rfirst[0].code}</td>
				  {rfirst[0].discountPercentage && <td>{rfirst[0].discountPercentage + "% Discount"}</td>}
				  {rfirst[0].absoluteDiscount && <td>{"Rs. " + rfirst[0].absoluteDiscount + " Discount"}</td>}
				  <td>{"Rs. " + rfirst[0].amountRangeMin + " - Rs. "  + rfirst[0].amountRangeMax}</td>
				</tr>
			{
				rmid.map((field, i) => {
					return( 
					<tr>
					  {field.discountPercentage && <td>{field.discountPercentage + "% Discount"}</td>}
					  {field.absoluteDiscount && <td>{"Rs. " + field.absoluteDiscount + " Discount"}</td>}
					  <td>{"Rs. " + field.amountRangeMin + " - Rs. "  + field.amountRangeMax}</td>
					</tr>
					)
				})
			}
				<tr>
				  {rlast[0].discountPercentage && <td>{rlast[0].discountPercentage + "% Discount"}</td>}
				  {rlast[0].absoluteDiscount && <td>{"Rs. " + rlast[0].absoluteDiscount + " Discount"}</td>}
				  <td>{"Above Rs. " + rlast[0].amountRangeMin}</td>
				</tr>
			  </table>
		  }
		  {
			  <ul className="bullet">
			  {offer.subCategoryL2s.length > 0 && <li>Applicable segment: {offer.subCategoryL2s[0].name}</li> }
			  {offer.maxDiscount && <li> Maximum discount : Rs. {offer.maxDiscount}</li>}
			  {offer.maxUsageCount && <li> Maximum usage count : {offer.maxUsageCount}</li>}
			  {offer.otherCriteria && <li> Other criteria : {offer.otherCriteria}</li>}
			  {offer.serviceEndDate && <li> Services should be consumed : {service}</li> }
			  {offer.newBankCustomersOnly && <li> Applicable for <strong>new {offer.bank.name} customers</strong> only</li>}
			  {offer.newStoreCustomersOnly && <li> Applicable for <strong>new {offer.store.name} customers</strong> only</li>}
			  {(specialNotes !== "") && <li dangerouslySetInnerHTML={{ __html: specialNotes }} /> }
			  </ul>
		  }
		  <BookNow offer={offer} />
		</div>
	  </div>
	  }
	  </>
)}

export default OfferDetails