import React from 'react';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import Banner from '../components/details/banner';
import OffersList from '../components/details/offers-list';
import Subscribe from "../components/category/subscribe";
import Faqs from "../components/category/faqs";
import OfferDetails from '../components/details/offer-details';
import "../styles/scss/pages/details/related-coupons.scss"

const DetailsPage = ( {data, pageContext} ) => {
	
	const offer = data.hc.offer;
	let pageData = pageContext.pageData;
	pageData.offer = offer;
	
	let bankOtherOffers = [];
	let allListpages = pageData.allListpages;
	for (var i in allListpages) {
		if ((offer.bank && allListpages[i].bank_id === offer.bank.id) || (offer.store && allListpages[i].store_id === offer.store.id)){
			bankOtherOffers.push(allListpages[i]);
		}
	}
	
	let others_h2 = [];
	if (offer.bank){ others_h2.push(offer.bank.name); }
	if (offer.store){ others_h2.push(offer.store.name); }
	
	return (
		<Layout page={pageContext.pageData}>
			<div className="details-page">
				<Banner offer={offer}/>
				<OffersList offer={offer}/>
				<OfferDetails offer={offer}/>
				<Subscribe />
				<Faqs page={pageContext.pageData} />
				
				{bankOtherOffers.length > 0 &&
				<div className="related-coupon-wrapper related-offer-list">
					<div className="container">
					<h2>Other {others_h2.join(", ")} Offers:</h2>
					<div className="all-list-wrapper">
						{bankOtherOffers.map(list => (
						<h3 key={list.id}> <Link to={list.url}>{list.h1}</Link></h3>
						))}
					</div>
					</div>
				</div>
				}

			</div>
		</Layout>
)};

export default DetailsPage;

export const query = graphql`
  query ($id: ID!) {
    hc {
	offer (where : {id : $id}) {	  
		name
		dealOfTheDay
		isFeatured
		categories{detailsPageBannerUrl}
		subCategoryL2s{name}
		store{ id name logoUrl website}
		bank{ id name logoUrl page {url} }
		cards{ description transactionType brand {logoUrl} }
		type{ id name }
		discountPercentage
		absoluteDiscount
		code
		minBookingAmount
		maxDiscount
		maxUsageCount
		otherCriteria
		channels{ id name }
		startDateTime
		endDateTime
		startTime
		endTime
		days{ iso name }
		serviceStartDate
		serviceEndDate
		newStoreCustomersOnly
		newBankCustomersOnly
		offerExtensions{ amountRangeMin amountRangeMax discountPercentage absoluteDiscount code }
		specialNotes{ html text }
		link{ landingUrl termsUrl }
		page{
		  url
		  gatsbyUrl
		  canonicalUrl
		  ampUrl
		  keywords
		  robots
		  title
		  description
		  about{html text}
		  h1
		  aboutH1{html text}
		  type
		  bannerUrl
		  pageStatus
		}		
		status
		disabled
	  }          
	}
  }
`;