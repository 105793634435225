import HcFunctions from "../utils/hc-functions";
const LEAD_API = "https://happycredit.in/hclist/subscribe";

class Lead {

    getCommonData() {
        let deviceInfo = HcFunctions.getDeviceInfo();
        let data = {
            page: window.location.href || "",
            user_agent: window.navigator.userAgent || "",
            os: deviceInfo.os.name,
            browser: deviceInfo.browser.name,
            browser_version: deviceInfo.browser.version,
            created_time: Date.now(),
        }
        return data;
    }

    createLead(formData) {
        const self = this;
        return new Promise((resolve, reject) => {
            let commonData = self.getCommonData();
            let leadData = { ...commonData, ...formData };
            let xhr = new XMLHttpRequest();
            xhr.withCredentials = true;
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    resolve(this.responseText);
                }
            });
            xhr.open("POST", LEAD_API);
            xhr.setRequestHeader("content-type", "application/json");
            xhr.send(JSON.stringify(leadData));
        })
    }
}

export default Lead;