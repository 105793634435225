import { Link } from "gatsby"
import React from "react"
import "../../styles/scss/pages/category/banner.scss"
import Helmet from "react-helmet"

const Breadcrumbs = ( {pageType, pageData, pageUrl} ) => {
	
	let baseUrl = "https://happycredit.in";
	let l1Link = "/";
	let l2Link = "/all-banks/";
	let l2Text = "All Banks";
	let l3Text = "Offers";
	
	// console.log(pageType);
	// console.log(pageData);
	
	if((pageType ==="Category") || (pageType ==="SubCategory")){
		l2Link = "/all-categories/";
		l2Text = "All Categories";
		l3Text = pageData.name + " Offers";
	} else if(pageType ==="Bank"){
		l2Link = "/all-banks/";
		l2Text = "All Banks";
		l3Text = pageData.name + " Offers";
	} else if(pageType ==="List"){
		
		//either store or bank has to be there on all list pages
		if(pageData.bank){
			l2Link = "/all-banks/";
			l2Text = "All Banks";

			l3Text = pageData.bank.name;
			if (pageData.store){ l3Text += " " + pageData.store.name ;}
		} else { //store must exist
			l2Link = "/all-stores/";
			l2Text = "All Stores";
			
			l3Text = pageData.store.name;
		}
		l3Text += " Offers";
	} else if(pageType ==="Store"){
		l2Link = "/all-stores/";
		l2Text = "All Stores";
		l3Text = pageData.name + " Offers";
	} else if(pageType ==="Offer"){
		if(pageData.bank){
			l2Link = pageData.bank.page.url;
			l2Text = pageData.bank.name;
		} else {
			//use default
			l2Link = "/all-banks/";
			l2Text = "All Banks";
		}
		
		if(pageData.store){
			l3Text = pageData.store.name + " Offer";
		}
	}
	
	const breadSchema = {
		"@context": "https://schema.org",
		"@type": "BreadcrumbList",
		"itemListElement": [
			{
				"@type": "ListItem",
				"position": 1,
				"name": "Home",
				"item": baseUrl + l1Link
			},{
				"@type": "ListItem",
				"position": 2,
				"name": l2Text,
				"item": baseUrl + l2Link,
			},{
				"@type": "ListItem",
				"position": 3,
				"name": l3Text,
				"item": baseUrl + pageUrl
			}
		]
	};
	
	return(
	<>	
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(breadSchema)}</script>
		</Helmet>
		<div className="breadcrumbs">
			<span><Link to="/">Home</Link></span>
			<span>></span>
			<span><Link to={l2Link}>{l2Text}</Link></span>
			<span>></span>
			<span>{l3Text}</span>
		</div>
	</>	
	)
}
export default Breadcrumbs